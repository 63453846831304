module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[{}],"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/_layouts/node_modules/gatsby-remark-wiki-link","id":"af50a6fa-693e-5e28-abb3-03fcfd1d4112","name":"gatsby-remark-wiki-link","version":"0.4.1","modulePath":"/opt/build/repo/_layouts/node_modules/gatsby-remark-wiki-link/lib/index.js","pluginOptions":{"plugins":[],"stripBrackets":false,"stripDefinitionExts":[".md",".mdx"]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/_layouts/node_modules/gatsby-remark-double-parenthesis-link","id":"7ee21003-1f07-5368-b50c-1c8775279826","name":"gatsby-remark-double-parenthesis-link","version":"0.1.5","modulePath":"/opt/build/repo/_layouts/node_modules/gatsby-remark-double-parenthesis-link/lib/index.js","module":{},"pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/_layouts/node_modules/gatsby-remark-copy-linked-files","id":"5df53bde-bc1c-5431-88ae-7e6761d2d83a","name":"gatsby-remark-copy-linked-files","version":"4.12.0","modulePath":"/opt/build/repo/_layouts/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[],"ignoreFileExtensions":["md","mdx"]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/_layouts/node_modules/gatsby-remark-prismjs","id":"b04498d2-0aaf-5ce5-be65-14bec212441a","name":"gatsby-remark-prismjs","version":"5.12.0","modulePath":"/opt/build/repo/_layouts/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"noInlineHighlight":false},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"rehypePlugins":[{}],"defaultLayouts":{},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo/_layouts","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-tocbot/gatsby-browser.js'),
      options: {"plugins":[],"tocbotOptions":{"contentSelector":".topic-layout__content","collapseDepth":5,"scrollContainer":".topic-layout__content"}},
    },{
      plugin: require('../node_modules/gatsby-theme-kb/gatsby-browser.js'),
      options: {"plugins":[],"rootNote":"/welcome","contentPath":"/opt/build/repo/_layouts/..","ignore":["**/_layouts/**","**/.git/**","**/.github/**","**/.vscode/**","**/.cache/**","**/.foam/templates/**"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
